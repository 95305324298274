import { facultyConstants } from "redux/constants";

const initState = {
  loading: false,
  error: null,
  message: null, 
  faculty: null 
};

const facultyReducer = (state = initState, action) => {  
  switch (action.type) {     
    case facultyConstants.MANAGE_FACULTY_REQUEST:
    case facultyConstants.GET_FACULTY_REQUEST:    
      return state = {
        ...state,
        loading: true
      }

    case facultyConstants.GET_FACULTY_SUCCESS:
      return state = {
        ...state,
        loading: false,
        faculty: action.payload.faculty
      }

    case facultyConstants.MANAGE_FACULTY_SUCCESS:
      return state = {
        ...state,
        loading: false,
        message: action.payload.message
      }

    case facultyConstants.MANAGE_FACULTY_FAILURE:
    case facultyConstants.GET_FACULTY_FAILURE:    
      return state = {
        ...state,
        loading: false,
        error: action.payload
      }
    
    case facultyConstants.RESET_ERROR_AND_MESSAGE:
      return{
        ...state,
        error: null,
        message: null        
      } 
      
    case facultyConstants.RESET:
      return {
        ...initState,        
      }
      
    default:
      return state;
  }
};

export default facultyReducer;