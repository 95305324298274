import { facultyConstants } from "redux/constants";
import Axios from "utils/axios";
import { getFaculties } from "./app.action";

export const manageFaculty = (id=null, form) => {
  return async dispatch => {
    try{
      dispatch({ type: facultyConstants.MANAGE_FACULTY_REQUEST });   
      let _data;
      if(id){
        _data = await Axios.put(`/faculty/${id}`, form);
      }else{
        _data = await Axios.post(`/faculty`, form);
      }
      const { data } = _data;      
      dispatch({ 
        type: facultyConstants.MANAGE_FACULTY_SUCCESS,
        payload: { message: data?.message } 
      });     
      if(data?.data){        
        dispatch(getFaculties());
      }
    }catch(error){
      dispatch({ 
        type: facultyConstants.MANAGE_FACULTY_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getFaculty = (id) => {
  return async dispatch => {
    try{
      dispatch({ type: facultyConstants.GET_FACULTY_REQUEST });
      const { data } = await Axios.get(`/faculty/${id}`);
      dispatch({ 
        type: facultyConstants.GET_FACULTY_SUCCESS,
        payload: { faculty: data?.data } 
      });
    }catch(error){
      dispatch({ 
        type: facultyConstants.GET_FACULTY_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}