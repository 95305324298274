import { formConstants } from "redux/constants";
import Axios from "utils/axios";

export const getForms = (id, filter, module) => {
  return async dispatch => {
    try{
      dispatch({ type: formConstants.GET_FORMS_REQUEST });   
      const { data } = await Axios.get(`/form/${module}?page=${filter?.page}&limit=${filter?.limit}&collegeId=${id}`);    
      if(module === "admission"){
        dispatch({ 
          type: formConstants.GET_ADMISSION_FORMS_SUCCESS,
          payload: data?.data
        });
      }else if(module === "exam"){
        dispatch({ 
          type: formConstants.GET_EXAM_FORMS_SUCCESS,
          payload: data?.data
        });
      }
    }catch(error){
      dispatch({ 
        type: formConstants.GET_FORMS_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getForm = (id, module) => {
  return async dispatch => {
    try{
      dispatch({ type: formConstants.GET_FORM_REQUEST });   
      const { data } = await Axios.get(`/form/${module}/${id}`);    
      if(module === "admission"){
        dispatch({ 
          type: formConstants.GET_ADMISSION_FORM_SUCCESS,
          payload: data?.data
        });
      }else if(module === "exam"){
        dispatch({ 
          type: formConstants.GET_EXAM_FORM_SUCCESS,
          payload: data?.data
        });
      }
    }catch(error){
      dispatch({ 
        type: formConstants.GET_FORM_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const manageForm = (id=null, form, module) => {
  return async dispatch => {
    try{
      dispatch({ type: formConstants.MANAGE_FORM_REQUEST });   
      let _data;
      if(id){
        _data = await Axios.put(`/form/${module}/${id}`, form);
      }else{
        _data = await Axios.post(`/form/${module}`, form);
      }
      const { data } = _data;      
      if(module === "admission"){
        dispatch({ 
          type: formConstants.MANAGE_ADMISSION_FORM_SUCCESS,
          payload: { message: data?.message, data: data?.data } 
        });           
      }else if(module === "exam"){
        dispatch({ 
          type: formConstants.MANAGE_EXAM_FORM_SUCCESS,
          payload: { message: data?.message, data: data?.data } 
        });           
      }
    }catch(error){
      dispatch({ 
        type: formConstants.MANAGE_FORM_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const syncFormCourses = (id, form, module) => {
  return async dispatch => {
    try{
      dispatch({ type: formConstants.SYNC_FORM_COURSES_REQUEST });   
      const { data } = await Axios.post(`/form/${module}/sync-courses/${id}`, form);    
      dispatch({ 
        type: formConstants.SYNC_FORM_COURSES_SUCCESS,
        payload: {
          message: data?.message,         
        }
      });      
      dispatch(getForm(id, module))
    }catch(error){
      dispatch({ 
        type: formConstants.SYNC_FORM_COURSES_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const manageFormCourseClassFee = (id, form, module) => {
  return async dispatch => {
    try{
      dispatch({ type: formConstants.MANAGE_FORM_COURSE_FEE_REQUEST });   
      const { data } = await Axios.put(`/form/${module}/course-fee/${id}`, form);    
      dispatch({ 
        type: formConstants.MANAGE_FORM_COURSE_FEE_SUCCESS,
        payload: {
          message: data?.message,         
        }
      });      
    }catch(error){
      dispatch({ 
        type: formConstants.MANAGE_FORM_COURSE_FEE_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const manageFormCourseClass = (id, form, module) => {
  return async dispatch => {
    try{
      dispatch({ type: formConstants.MANAGE_FORM_COURSE_REQUEST });   
      const { data } = await Axios.put(`/form/${module}/course/${id}`, form);    
      dispatch({ 
        type: formConstants.MANAGE_FORM_COURSE_SUCCESS,
        payload: {
          message: data?.message,         
        }
      });      
    }catch(error){
      dispatch({ 
        type: formConstants.MANAGE_FORM_COURSE_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}