import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Loading } from "./@extended/Loading";
import { 
  admissionConstants,
  appConstants, 
  collegeConstants, 
  courseConstants, 
  examConstants,
  facultyConstants, 
  formConstants,
  sessionConstants,
  subjectConstants, 
  universityConstants,
  userConstants 
} from "redux/constants";

const MessageAndErrors = () => {

  const dispatch = useDispatch();
  const { message: admissionMsg, error: admissionErr, loading: admissionLoading } = useSelector(state => state.admission);
  const { message: appMsg, error: appErr, loading: appLoading } = useSelector(state => state.app);
  const { message: collegeMsg, error: collegeErr, loading: collegeLoading } = useSelector(state => state.college);
  const { message: courseMsg, error: courseErr, loading: courseLoading } = useSelector(state => state.course);
  const { message: examMsg, error: examErr, loading: examLoading } = useSelector(state => state.exam);
  const { message: facultyMsg, error: facultyErr, loading: facultyLoading } = useSelector(state => state.faculty);
  const { message: formMsg, error: formErr, loading: formLoading } = useSelector(state => state.form);
  const { message: sessionMsg, error: sessionErr, loading: sessionLoading } = useSelector(state => state.session);
  const { message: subjectMsg, error: subjectErr, loading: subjectLoading } = useSelector(state => state.subject);  
  const { message: universityMsg, error: universityErr, loading: universityLoading } = useSelector(state => state.university);
  const { message: userMsg, error: userErr, loading: userLoading } = useSelector(state => state.user);

  useEffect(() => {
    if(admissionMsg){
      toast.success(admissionMsg)
      dispatch({ type: admissionConstants.RESET_ERROR_AND_MESSAGE })
    }
    if(admissionErr){
      toast.error(admissionErr)
      dispatch({ type: admissionConstants.RESET_ERROR_AND_MESSAGE })
    }
    if(appMsg){
      toast.success(appMsg)
      dispatch({ type: appConstants.RESET_ERROR_AND_MESSAGE })
    }
    if(appErr){
      toast.error(appErr)
      dispatch({ type: appConstants.RESET_ERROR_AND_MESSAGE })
    }
    if(collegeMsg){
      toast.success(collegeMsg)
      dispatch({ type: collegeConstants.RESET_ERROR_AND_MESSAGE })
    }
    if(collegeErr){
      toast.error(collegeErr)
      dispatch({ type: collegeConstants.RESET_ERROR_AND_MESSAGE })
    }
    if(courseMsg){
      toast.success(courseMsg)
      dispatch({ type: courseConstants.RESET_ERROR_AND_MESSAGE })
    }
    if(courseErr){
      toast.error(courseErr)
      dispatch({ type: courseConstants.RESET_ERROR_AND_MESSAGE })
    }
    if(examMsg){
      toast.success(examMsg)
      dispatch({ type: examConstants.RESET_ERROR_AND_MESSAGE })
    }
    if(examErr){
      toast.error(examErr)
      dispatch({ type: examConstants.RESET_ERROR_AND_MESSAGE })
    }
    if(facultyMsg){
      toast.success(facultyMsg)
      dispatch({ type: facultyConstants.RESET_ERROR_AND_MESSAGE })
    }
    if(facultyErr){
      toast.error(facultyErr)
      dispatch({ type: facultyConstants.RESET_ERROR_AND_MESSAGE })
    }
    if(formMsg){
      toast.success(formMsg)
      dispatch({ type: formConstants.RESET_ERROR_AND_MESSAGE })
    }
    if(formErr){
      toast.error(formErr)
      dispatch({ type: formConstants.RESET_ERROR_AND_MESSAGE })
    }
    if(sessionMsg){
      toast.success(sessionMsg)
      dispatch({ type: sessionConstants.RESET_ERROR_AND_MESSAGE })
    }
    if(sessionErr){
      toast.error(sessionErr)
      dispatch({ type: sessionConstants.RESET_ERROR_AND_MESSAGE })
    }
    if(subjectMsg){
      toast.success(subjectMsg)
      dispatch({ type: subjectConstants.RESET_ERROR_AND_MESSAGE })
    }
    if(subjectErr){
      toast.error(subjectErr)
      dispatch({ type: subjectConstants.RESET_ERROR_AND_MESSAGE })
    }
    if(universityMsg){
      toast.success(universityMsg)
      dispatch({ type: universityConstants.RESET_ERROR_AND_MESSAGE })
    }
    if(universityErr){
      toast.error(universityErr)
      dispatch({ type: universityConstants.RESET_ERROR_AND_MESSAGE })
    }
    if(userMsg){
      toast.success(userMsg)
      dispatch({ type: userConstants.RESET_ERROR_AND_MESSAGE })
    }
    if(userErr){
      toast.error(userErr)
      dispatch({ type: userConstants.RESET_ERROR_AND_MESSAGE })
    }    
  }, [
    dispatch,
    admissionMsg,
    admissionErr,
    appMsg, 
    appErr, 
    collegeMsg, 
    collegeErr, 
    courseMsg, 
    courseErr, 
    facultyMsg, 
    facultyErr,
    formMsg,
    formErr,
    examMsg,
    examErr,
    sessionMsg,
    sessionErr, 
    subjectMsg, 
    subjectErr, 
    universityMsg, 
    universityErr,
    userMsg, 
    userErr
  ]);

  return (
    <>
      <ToastContainer         
        style={{ fontSize: "12px" }}  
        position="top-right"      
      />
      <Loading
        loading={
          admissionLoading ||
          appLoading || 
          collegeLoading || 
          courseLoading || 
          examLoading ||
          facultyLoading || 
          formLoading ||
          sessionLoading ||
          subjectLoading || 
          universityLoading ||
          userLoading
        }
      />      
    </>
  )
}

export default MessageAndErrors;