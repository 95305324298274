export const menuConstants = {
  ACTIVE_ITEM: "ACTIVE_ITEM",
  ACTIVE_COMPONENT: "ACTIVE_COMPONENT",
  OPEN_DRAWER: "OPEN_DRAWER",
  OPEN_COMPONENT_DRAWER: "OPEN_COMPONENT_DRAWER"
};

export const userConstants = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",
  LOAD_USER_REQUEST: "LOAD_USER_REQUEST",
  LOAD_USER_SUCCESS: "LOAD_USER_SUCCESS",
  LOAD_USER_FAILURE: "LOAD_USER_FAILURE",
  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",
  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
  RESET_ERROR_AND_MESSAGE: "RESET_ERROR_AND_MESSAGE",
  RESET: "RESET"
}

export const appConstants = {
  GET_INITIAL_DATA_REQUEST: "GET_INITIAL_DATA_REQUEST",
  GET_INITIAL_DATA_SUCCESS: "GET_INITIAL_DATA_SUCCESS",
  GET_INITIAL_DATA_FAILURE: "GET_INITIAL_DATA_FAILURE",
  UPDATE_COLLEGE_IN_UNIVERSITY: "UPDATE_COLLEGE_IN_UNIVERSITY",
  SELECT_UNIVERSITY: "SELECT_UNIVERSITY",
  GET_FACULTIES_REQUEST: "GET_FACULTIES_REQUEST",
  GET_FACULTIES_SUCCESS: "GET_FACULTIES_SUCCESS",
  GET_FACULTIES_FAILURE: "GET_FACULTIES_FAILURE",
  MANAGE_STUDENT_CATEGORY_REQUEST: "MANAGE_STUDENT_CATEGORY_REQUEST",
  MANAGE_STUDENT_CATEGORY_SUCCESS: "MANAGE_STUDENT_CATEGORY_SUCCESS",
  MANAGE_STUDENT_CATEGORY_FAILURE: "MANAGE_STUDENT_CATEGORY_FAILURE",
  GET_STUDENT_CATEGORY_REQUEST: "GET_STUDENT_CATEGORY_REQUEST",
  GET_STUDENT_CATEGORY_SUCCESS: "GET_STUDENT_CATEGORY_SUCCESS",
  GET_STUDENT_CATEGORY_FAILURE: "GET_STUDENT_CATEGORY_FAILURE",    
  UPDATE_COURSE_TYPE: "UPDATE_COURSE_TYPE",
  UPDATE_COURSE: "UPDATE_COURSE",
  UPDATE_SUBJECT: "UPDATE_SUBJECT",
  UPDATE_EXAM_TYPE: "UPDATE_EXAM_TYPE",
  UPDATE_SESSION: "UPDATE_SESSION",
  SET_REDIRECT: "SET_REDIRECT",
  UPDATE_PASSWORD_REQUEST: "UPDATE_PASSWORD_REQUEST",
  UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_FAILURE: "UPDATE_PASSWORD_FAILURE",
  UPDATE_AVATAR_REQUEST: "UPDATE_AVATAR_REQUEST",
  UPDATE_AVATAR_SUCCESS: "UPDATE_AVATAR_SUCCESS",
  UPDATE_AVATAR_FAILURE: "UPDATE_AVATAR_FAILURE",
  RESET_ERROR_AND_MESSAGE: "RESET_ERROR_AND_MESSAGE",
  RESET: "RESET"
}

export const universityConstants = {
  MANAGE_UNIVERSITY_REQUEST: "MANAGE_UNIVERSITY_REQUEST",
  MANAGE_UNIVERSITY_SUCCESS: "MANAGE_UNIVERSITY_SUCCESS",
  MANAGE_UNIVERSITY_FAILURE: "MANAGE_UNIVERSITY_FAILURE",  
  GET_UNIVERSITY_REQUEST: "GET_UNIVERSITY_REQUEST",
  GET_UNIVERSITY_SUCCESS: "GET_UNIVERSITY_SUCCESS",
  GET_UNIVERSITY_FAILURE: "GET_UNIVERSITY_FAILURE",  
  RESET_ERROR_AND_MESSAGE: "RESET_ERROR_AND_MESSAGE",
  RESET: "RESET"
}

export const collegeConstants = {
  MANAGE_COLLEGE_REQUEST: "MANAGE_COLLEGE_REQUEST",
  MANAGE_COLLEGE_SUCCESS: "MANAGE_COLLEGE_SUCCESS",
  MANAGE_COLLEGE_FAILURE: "MANAGE_COLLEGE_FAILURE",
  GET_COLLEGE_REQUEST: "GET_COLLEGE_REQUEST",
  GET_COLLEGE_SUCCESS: "GET_COLLEGE_SUCCESS",
  GET_COLLEGE_FAILURE: "GET_COLLEGE_FAILURE",  
  ADD_COLLEGE_DOCUMENT_REQUEST: "ADD_COLLEGE_DOCUMENT_REQUEST",
  ADD_COLLEGE_DOCUMENT_SUCCESS: "ADD_COLLEGE_DOCUMENT_SUCCESS",
  ADD_COLLEGE_DOCUMENT_FAILURE: "ADD_COLLEGE_DOCUMENT_FAILURE",
  UPDATE_ACTIVE_COURSE_IN_COLLEGE_REQUEST: "UPDATE_ACTIVE_COURSE_IN_COLLEGE_REQUEST",
  UPDATE_ACTIVE_COURSE_IN_COLLEGE_SUCCESS: "UPDATE_ACTIVE_COURSE_IN_COLLEGE_SUCCESS",
  UPDATE_ACTIVE_COURSE_IN_COLLEGE_FAILURE: "UPDATE_ACTIVE_COURSE_IN_COLLEGE_FAILURE",
  MANAGE_COLLEGE_ADDONS_REQUEST: "MANAGE_COLLEGE_ADDONS_REQUEST",
  MANAGE_COLLEGE_ADDONS_SUCCESS: "MANAGE_COLLEGE_ADDONS_SUCCESS",
  MANAGE_COLLEGE_ADDONS_FAILURE: "MANAGE_COLLEGE_ADDONS_FAILURE",  
  CREATE_COLLEGE_USER_REQUEST: "CREATE_COLLEGE_USER_REQUEST",
  CREATE_COLLEGE_USER_SUCCESS: "CREATE_COLLEGE_USER_SUCCESS",
  CREATE_COLLEGE_USER_FAILURE: "CREATE_COLLEGE_USER_FAILURE",
  MANAGE_COLLEGE_LIBRARY_REQUEST: "MANAGE_COLLEGE_LIBRARY_REQUEST",
  MANAGE_COLLEGE_LIBRARY_SUCCESS: "MANAGE_COLLEGE_LIBRARY_SUCCESS",
  MANAGE_COLLEGE_LIBRARY_FAILURE: "MANAGE_COLLEGE_LIBRARY_FAILURE",
  GET_LIBRARY_REQUEST: "GET_LIBRARY_REQUEST",
  GET_LIBRARY_SUCCESS: "GET_LIBRARY_SUCCESS",
  GET_LIBRARY_FAILURE: "GET_LIBRARY_FAILURE",
  CREATE_LIBRARY_USER_REQUEST: "CREATE_LIBRARY_USER_REQUEST",
  CREATE_LIBRARY_USER_SUCCESS: "CREATE_LIBRARY_USER_SUCCESS",
  CREATE_LIBRARY_USER_FAILURE: "CREATE_LIBRARY_USER_FAILURE",
  UPDATE_LIBRARY_USER_REQUEST: "UPDATE_LIBRARY_USER_REQUEST",
  UPDATE_LIBRARY_USER_SUCCESS: "UPDATE_LIBRARY_USER_SUCCESS",
  UPDATE_LIBRARY_USER_FAILURE: "UPDATE_LIBRARY_USER_FAILURE",
  GET_COLLEGE_STUDENTS_REQUEST: "GET_COLLEGE_STUDENTS_REQUEST",
  GET_COLLEGE_STUDENTS_SUCCESS: "GET_COLLEGE_STUDENTS_SUCCESS",
  GET_COLLEGE_STUDENTS_FAILURE: "GET_COLLEGE_STUDENTS_FAILURE",
  RESET_ERROR_AND_MESSAGE: "RESET_ERROR_AND_MESSAGE",
  RESET: "RESET"
}

export const facultyConstants = {
  MANAGE_FACULTY_REQUEST: "MANAGE_FACULTY_REQUEST",
  MANAGE_FACULTY_SUCCESS: "MANAGE_FACULTY_SUCCESS",
  MANAGE_FACULTY_FAILURE: "MANAGE_FACULTY_FAILURE",
  GET_FACULTY_REQUEST: "GET_FACULTY_REQUEST",
  GET_FACULTY_SUCCESS: "GET_FACULTY_SUCCESS",
  GET_FACULTY_FAILURE: "GET_FACULTY_FAILURE",  
  RESET_ERROR_AND_MESSAGE: "RESET_ERROR_AND_MESSAGE",
  RESET: "RESET"
}

export const courseConstants = {
  MANAGE_COURSE_TYPE_REQUEST: "MANAGE_COURSE_TYPE_REQUEST",
  MANAGE_COURSE_TYPE_SUCCESS: "MANAGE_COURSE_TYPE_SUCCESS",
  MANAGE_COURSE_TYPE_FAILURE: "MANAGE_COURSE_TYPE_FAILURE",
  GET_COURSE_TYPE_REQUEST: "GET_COURSE_TYPE_REQUEST",
  GET_COURSE_TYPE_SUCCESS: "GET_COURSE_TYPE_SUCCESS",
  GET_COURSE_TYPE_FAILURE: "GET_COURSE_TYPE_FAILURE",  
  MANAGE_COURSE_REQUEST: "MANAGE_COURSE_REQUEST",
  MANAGE_COURSE_SUCCESS: "MANAGE_COURSE_SUCCESS",
  MANAGE_COURSE_FAILURE: "MANAGE_COURSE_FAILURE",
  GET_COURSE_REQUEST: "GET_COURSE_REQUEST",
  GET_COURSE_SUCCESS: "GET_COURSE_SUCCESS",
  GET_COURSE_FAILURE: "GET_COURSE_FAILURE",  
  ADD_COURSE_DOCUMENT_REQUEST: "ADD_COURSE_DOCUMENT_REQUEST",
  ADD_COURSE_DOCUMENT_SUCCESS: "ADD_COURSE_DOCUMENT_SUCCESS",
  ADD_COURSE_DOCUMENT_FAILURE: "ADD_COURSE_DOCUMENT_FAILURE",
  GET_COURSE_CLASS_REQUEST: "GET_COURSE_CLASS_REQUEST",
  GET_COURSE_CLASS_SUCCESS: "GET_COURSE_CLASS_SUCCESS",
  GET_COURSE_CLASS_FAILURE: "GET_COURSE_CLASS_FAILURE",  
  UPDATE_COURSE_CLASS_REQUEST: "UPDATE_COURSE_CLASS_REQUEST",
  UPDATE_COURSE_CLASS_SUCCESS: "UPDATE_COURSE_CLASS_SUCCESS",
  UPDATE_COURSE_CLASS_FAILURE: "UPDATE_COURSE_CLASS_FAILURE",
  MANAGE_SUBJECT_IN_COURSE_CLASS_REQUEST: "MANAGE_SUBJECT_IN_COURSE_CLASS_REQUEST",
  MANAGE_SUBJECT_IN_COURSE_CLASS_SUCCESS: "MANAGE_SUBJECT_IN_COURSE_CLASS_SUCCESS",
  MANAGE_SUBJECT_IN_COURSE_CLASS_FAILURE: "MANAGE_SUBJECT_IN_COURSE_CLASS_FAILURE",
  MANAGE_SUBJECT_MARKS_REQUEST: "MANAGE_SUBJECT_MARKS_REQUEST",
  MANAGE_SUBJECT_MARKS_SUCCESS: "MANAGE_SUBJECT_MARKS_SUCCESS",
  MANAGE_SUBJECT_MARKS_FAILURE: "MANAGE_SUBJECT_MARKS_FAILURE",
  GET_COURSES_REQUEST: "GET_COURSES_REQUEST",
  GET_COURSES_SUCCESS: "GET_COURSES_SUCCESS",
  GET_COURSES_FAILURE: "GET_COURSES_FAILURE",
  RESET_ERROR_AND_MESSAGE: "RESET_ERROR_AND_MESSAGE",
  RESET: "RESET"
}

export const subjectConstants = {
  MANAGE_SUBJECT_REQUEST: "MANAGE_SUBJECT_REQUEST",
  MANAGE_SUBJECT_SUCCESS: "MANAGE_SUBJECT_SUCCESS",
  MANAGE_SUBJECT_FAILURE: "MANAGE_SUBJECT_FAILURE",
  GET_SUBJECT_REQUEST: "GET_SUBJECT_REQUEST",
  GET_SUBJECT_SUCCESS: "GET_SUBJECT_SUCCESS",
  GET_SUBJECT_FAILURE: "GET_SUBJECT_FAILURE",  
  ADD_SUBJECT_DOCUMENT_REQUEST: "ADD_SUBJECT_DOCUMENT_REQUEST",
  ADD_SUBJECT_DOCUMENT_SUCCESS: "ADD_SUBJECT_DOCUMENT_SUCCESS",
  ADD_SUBJECT_DOCUMENT_FAILURE: "ADD_SUBJECT_DOCUMENT_FAILURE",
  GET_SUBJECTS_REQUEST: "GET_SUBJECTS_REQUEST",
  GET_SUBJECTS_SUCCESS: "GET_SUBJECTS_SUCCESS",
  GET_SUBJECTS_FAILURE: "GET_SUBJECTS_FAILURE",  
  RESET_ERROR_AND_MESSAGE: "RESET_ERROR_AND_MESSAGE",
  RESET: "RESET"
}

export const examConstants = {
  MANAGE_EXAM_TYPE_REQUEST: "MANAGE_EXAM_TYPE_REQUEST",
  MANAGE_EXAM_TYPE_SUCCESS: "MANAGE_EXAM_TYPE_SUCCESS",
  MANAGE_EXAM_TYPE_FAILURE: "MANAGE_EXAM_TYPE_FAILURE",  
  GET_EXAM_TYPE_REQUEST: "GET_EXAM_TYPE_REQUEST",
  GET_EXAM_TYPE_SUCCESS: "GET_EXAM_TYPE_SUCCESS",
  GET_EXAM_TYPE_FAILURE: "GET_EXAM_TYPE_FAILURE",
  RESET_ERROR_AND_MESSAGE: "RESET_ERROR_AND_MESSAGE",
  RESET: "RESET"
}

export const sessionConstants = {
  MANAGE_SESSION_REQUEST: "MANAGE_SESSION_REQUEST",
  MANAGE_SESSION_SUCCESS: "MANAGE_SESSION_SUCCESS",
  MANAGE_SESSION_FAILURE: "MANAGE_SESSION_FAILURE",
  GET_SESSION_REQUEST: "GET_SESSION_REQUEST",
  GET_SESSION_SUCCESS: "GET_SESSION_SUCCESS",
  GET_SESSION_FAILURE: "GET_SESSION_FAILURE",
  RESET_ERROR_AND_MESSAGE: "RESET_ERROR_AND_MESSAGE",
  RESET: "RESET"
}

export const admissionConstants = {
  MANAGE_ADMISSION_REQUEST: "MANAGE_ADMISSION_REQUEST",
  MANAGE_ADMISSION_SUCCESS: "MANAGE_ADMISSION_SUCCESS",
  MANAGE_ADMISSION_FAILURE: "MANAGE_ADMISSION_FAILURE",
  GET_ADMISSIONS_REQUEST: "GET_ADMISSIONS_REQUEST",
  GET_ADMISSIONS_SUCCESS: "GET_ADMISSIONS_SUCCESS",
  GET_ADMISSIONS_FAILURE: "GET_ADMISSIONS_FAILURE",
  GET_ADMISSION_REQUEST: "GET_ADMISSION_REQUEST",
  GET_ADMISSION_SUCCESS: "GET_ADMISSION_SUCCESS",
  GET_ADMISSION_FAILURE: "GET_ADMISSION_FAILURE",
  MANAGE_ADMISSION_COURSE_REQUEST: "MANAGE_ADMISSION_COURSE_REQUEST",
  MANAGE_ADMISSION_COURSE_SUCCESS: "MANAGE_ADMISSION_COURSE_SUCCESS",
  MANAGE_ADMISSION_COURSE_FAILURE: "MANAGE_ADMISSION_COURSE_FAILURE",
  MANAGE_ADMISSION_COURSE_FEE_REQUEST: "MANAGE_ADMISSION_COURSE_FEE_REQUEST",
  MANAGE_ADMISSION_COURSE_FEE_SUCCESS: "MANAGE_ADMISSION_COURSE_FEE_SUCCESS",
  MANAGE_ADMISSION_COURSE_FEE_FAILURE: "MANAGE_ADMISSION_COURSE_FEE_FAILURE",
  RESET_ERROR_AND_MESSAGE: "RESET_ERROR_AND_MESSAGE",
  RESET: "RESET"
}

export const formConstants = {
  GET_FORMS_REQUEST: "GET_FORMS_REQUEST",
  GET_ADMISSION_FORMS_SUCCESS: "GET_ADMISSION_FORMS_SUCCESS",
  GET_EXAM_FORMS_SUCCESS: "GET_EXAM_FORMS_SUCCESS",
  GET_FORMS_FAILURE: "GET_FORMS_FAILURE",
  GET_FORM_REQUEST: "GET_FORM_REQUEST",
  GET_ADMISSION_FORM_SUCCESS: "GET_ADMISSION_FORM_SUCCESS",
  GET_EXAM_FORM_SUCCESS: "GET_EXAM_FORM_SUCCESS",
  GET_FORM_FAILURE: "GET_FORM_FAILURE",
  MANAGE_FORM_REQUEST: "MANAGE_FORM_REQUEST",
  MANAGE_ADMISSION_FORM_SUCCESS: "MANAGE_ADMISSION_FORM_SUCCESS",
  MANAGE_EXAM_FORM_SUCCESS: "MANAGE_EXAM_FORM_SUCCESS",
  MANAGE_FORM_FAILURE: "MANAGE_FORM_FAILURE",
  SYNC_FORM_COURSES_REQUEST: "SYNC_FORM_COURSES_REQUEST",
  SYNC_FORM_COURSES_SUCCESS: "SYNC_FORM_COURSES_SUCCESS",
  SYNC_FORM_COURSES_FAILURE: "SYNC_FORM_COURSES_FAILURE",
  MANAGE_FORM_COURSE_REQUEST: "MANAGE_FORM_COURSE_REQUEST",
  MANAGE_FORM_COURSE_SUCCESS: "MANAGE_FORM_COURSE_SUCCESS",
  MANAGE_FORM_COURSE_FAILURE: "MANAGE_FORM_COURSE_FAILURE",
  MANAGE_FORM_COURSE_FEE_REQUEST: "MANAGE_FORM_COURSE_FEE_REQUEST",
  MANAGE_FORM_COURSE_FEE_SUCCESS: "MANAGE_FORM_COURSE_FEE_SUCCESS",
  MANAGE_FORM_COURSE_FEE_FAILURE: "MANAGE_FORM_COURSE_FEE_FAILURE",  
  RESET_ERROR_AND_MESSAGE: "RESET_ERROR_AND_MESSAGE",
  RESET: "RESET"
}