import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import HistoryEduOutlinedIcon from '@mui/icons-material/HistoryEduOutlined';

const icons = {    
  HistoryEduOutlinedIcon,
  CategoryOutlinedIcon,
};

const additionals = {
  id: "additional",
  title: "Additional",
  type: "group",
  children: [    
    {
      id: "exam-type",
      title: "Exam Type",
      type: "item",
      url: "/exam/type",
      icon: icons.HistoryEduOutlinedIcon,
      breadcrumbs: true,      
    }, 
    {
      id: "student-category",
      title: "Student Category",
      type: "item",
      url: "/student-category",
      icon: icons.CategoryOutlinedIcon,
      breadcrumbs: true,
    }   
  ]
};

export default additionals;