import { appConstants } from "redux/constants";
import { subjectConstants } from "redux/constants";
import Axios from "utils/axios";

export const manageSubject = (id=null, form) => {
  return async dispatch => {
    try{
      dispatch({ type: subjectConstants.MANAGE_SUBJECT_REQUEST });
      let _data;
      if(id){
        _data = await Axios.put(`/subject/${id}`, form);
      }else{
        _data = await Axios.post(`/subject`, form);
      }
      const { data } = _data;      
      dispatch({ 
        type: subjectConstants.MANAGE_SUBJECT_SUCCESS,
        payload: { message: data?.message } 
      });
    }catch(error){
      dispatch({ 
        type: subjectConstants.MANAGE_SUBJECT_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getSubject = (id) => {
  return async dispatch => {
    try{
      dispatch({ type: subjectConstants.GET_SUBJECT_REQUEST });
      const { data } = await Axios.get(`/subject/${id}`);
      dispatch({ 
        type: subjectConstants.GET_SUBJECT_SUCCESS,
        payload: { subject: data?.data } 
      });
    }catch(error){
      dispatch({ 
        type: subjectConstants.GET_SUBJECT_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const addSubjectDocument = (form) => {
  return async dispatch => {
    try{
      dispatch({ type: subjectConstants.ADD_SUBJECT_DOCUMENT_REQUEST });
      const { data } = await Axios.post(`/subject/document`, form);            
      dispatch({ 
        type: subjectConstants.ADD_SUBJECT_DOCUMENT_SUCCESS,
        payload: { 
          message: data?.message,
          document: data?.data,
        } 
      });
    }catch(error){
      dispatch({ 
        type: subjectConstants.ADD_SUBJECT_DOCUMENT_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getSubjects = (filter) => {
  return async dispatch => {
    try{
      dispatch({ type: subjectConstants.GET_SUBJECTS_REQUEST });
      const { query, page, limit } = filter;
      let URL = `/subject?limit=${limit}&page=${page}`;
      if(query) URL += `&query=${query}`;
      const { data } = await Axios.get(URL);
      dispatch({ 
        type: subjectConstants.GET_SUBJECTS_SUCCESS,
        payload: data?.data
      });
    }catch(error){
      dispatch({ 
        type: subjectConstants.GET_SUBJECTS_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}