import { subjectConstants } from "redux/constants";

const initState = {
  loading: false,
  error: null,
  message: null,  
  subject: null,
  subjectData: null,
};

const subjectReducer = (state = initState, action) => {  
  let temp = null;
  switch (action.type) {    
    case subjectConstants.MANAGE_SUBJECT_REQUEST:
    case subjectConstants.GET_SUBJECT_REQUEST:    
    case subjectConstants.ADD_SUBJECT_DOCUMENT_REQUEST:
    case subjectConstants.GET_SUBJECTS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case subjectConstants.MANAGE_SUBJECT_SUCCESS:
        return {
          ...state,
          loading: false,
          message: action.payload.message,
        };
        
    case subjectConstants.GET_SUBJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        subject: action.payload.subject,
      };

    case subjectConstants.ADD_SUBJECT_DOCUMENT_SUCCESS:
      temp = state.subject;
      let doc = action.payload?.document;
      if(doc && temp?.id === doc?.subjectId){
        temp.subjectDocuments = [...temp.subjectDocuments, doc];
      }
      return {
        ...state,
        loading: false,
        subject: temp,
        message: action.payload.message,
      };

    case subjectConstants.GET_SUBJECTS_SUCCESS:
      return {
        ...state,
        subjectData: action.payload,
        loading: false,
      };
    
    case subjectConstants.MANAGE_SUBJECT_FAILURE:
    case subjectConstants.GET_SUBJECT_FAILURE:    
    case subjectConstants.ADD_SUBJECT_DOCUMENT_FAILURE:
    case subjectConstants.GET_SUBJECTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case subjectConstants.RESET_ERROR_AND_MESSAGE:
      return{
        ...state,
        error: null,
        message: null        
      } 
      
    case subjectConstants.RESET:
      return {
        ...initState,        
      }
      
    default:
      return state;
  }
};

export default subjectReducer;