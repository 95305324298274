import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import LockClockOutlinedIcon from '@mui/icons-material/LockClockOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import MoreOutlinedIcon from '@mui/icons-material/MoreOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/SchoolOutlined';

const icons = {    
  MoreOutlinedIcon,
  StickyNote2OutlinedIcon,
  MenuBookOutlinedIcon,
  LockClockOutlinedIcon,
  HowToRegOutlinedIcon
};

const academics = {
  id: "academics",
  title: "Academics",
  type: "group",
  children: [
    {
      id: "all-courses",
      title: "All Courses",
      type: "item",
      url: "/course",
      icon: icons.StickyNote2OutlinedIcon,
      breadcrumbs: true,      
    },
    {
      id: "admission",
      title: "Admission",
      type: "item",
      url: "/admission",
      icon: icons.HowToRegOutlinedIcon,
      breadcrumbs: true,      
    },
    {
      id: "course-type",
      title: "Course Type",
      type: "item",
      url: "/course/type",
      icon: icons.MoreOutlinedIcon,
      breadcrumbs: true,      
    }, 
    {
      id: "subject",
      title: "Subjects",
      type: "item",
      url: "/subject",
      icon: icons.MenuBookOutlinedIcon,
      breadcrumbs: true,      
    },
    {
      id: "session",
      title: "Sessions",
      type: "item",
      url: "/session",
      icon: icons.LockClockOutlinedIcon,
      breadcrumbs: true,      
    }     
  ]
};

export default academics;