import { courseConstants } from "redux/constants";

const initState = {
  loading: false,
  error: null,
  message: null, 
  courseType: null,
  courseData: null,
  course: null,
  courseClass: null
};

const courseReducer = (state = initState, action) => {  
  let temp = null;
  switch (action.type) {             
    case courseConstants.MANAGE_COURSE_TYPE_REQUEST:
    case courseConstants.GET_COURSE_TYPE_REQUEST:    
    case courseConstants.MANAGE_COURSE_REQUEST:
    case courseConstants.GET_COURSE_REQUEST:    
    case courseConstants.ADD_COURSE_DOCUMENT_REQUEST:
    case courseConstants.GET_COURSE_CLASS_REQUEST:
    case courseConstants.UPDATE_COURSE_CLASS_REQUEST:
    case courseConstants.MANAGE_SUBJECT_IN_COURSE_CLASS_REQUEST:
    case courseConstants.MANAGE_SUBJECT_MARKS_REQUEST:
    case courseConstants.GET_COURSES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case courseConstants.MANAGE_COURSE_TYPE_SUCCESS:    
    case courseConstants.MANAGE_COURSE_SUCCESS:    
    case courseConstants.UPDATE_COURSE_CLASS_SUCCESS:
    case courseConstants.MANAGE_SUBJECT_IN_COURSE_CLASS_SUCCESS:
        return {
          ...state,
          loading: false,
          message: action.payload.message,
        };
        
    case courseConstants.GET_COURSE_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        courseType: action.payload.courseType,
      };

    case courseConstants.GET_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        course: action.payload.course,
      };

    case courseConstants.ADD_COURSE_DOCUMENT_SUCCESS:
      temp = state.course;
      let doc = action.payload?.document;
      if(doc && temp?.id === doc?.courseId){
        temp.courseDocuments = [...temp.courseDocuments, doc];
      }
      return {
        ...state,
        loading: false,
        course: temp,
        message: action.payload.message,
      };

    case courseConstants.GET_COURSE_CLASS_SUCCESS:
      return {
        ...state,
        loading: false,
        courseClass: action.payload.courseClass,
      };

    case courseConstants.MANAGE_SUBJECT_MARKS_SUCCESS:
      temp = state.courseClass;
      let marks = action.payload?.marks;
      let subject = temp?.courseClassSubjects?.find(s => s.id === marks?.subjectId);
      if(subject){
        let _marks = subject?.courseSubjectExamMarks?.find(m => m.id === marks?.id);
        if(_marks){
          subject.courseSubjectExamMarks = subject?.courseSubjectExamMarks?.map(m => {
            if(m.id === marks?.id){
              return marks;
            }
            return m;
          });
        }else{
          subject.courseSubjectExamMarks = [...subject.courseSubjectExamMarks, marks];
        }
      }

      return {
        ...state,
        loading: false,
        courseClass: temp,
        message: action.payload.message,
      };

    case courseConstants.GET_COURSES_SUCCESS:
      return {
        ...state,
        loading: false,
        courseData: action.payload,
      };

    case courseConstants.MANAGE_COURSE_TYPE_FAILURE:
    case courseConstants.GET_COURSE_TYPE_FAILURE:    
    case courseConstants.MANAGE_COURSE_FAILURE:
    case courseConstants.GET_COURSE_FAILURE:    
    case courseConstants.ADD_COURSE_DOCUMENT_FAILURE:
    case courseConstants.GET_COURSE_CLASS_FAILURE:
    case courseConstants.UPDATE_COURSE_CLASS_FAILURE:
    case courseConstants.MANAGE_SUBJECT_IN_COURSE_CLASS_FAILURE:
    case courseConstants.MANAGE_SUBJECT_MARKS_FAILURE:
    case courseConstants.GET_COURSES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    
    case courseConstants.RESET_ERROR_AND_MESSAGE:
      return{
        ...state,
        error: null,
        message: null        
      } 
      
    case courseConstants.RESET:
      return {
        ...initState,        
      }

    default:
      return state;
  }
};

export default courseReducer;