import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';

const icons = {  
  ApartmentOutlinedIcon,
  BookOutlinedIcon,
  SchoolOutlinedIcon
};

const pages = {
  id: "university",
  title: "University",
  type: "group",
  children: [
    {
      id: "university",
      title: "University",
      type: "item",
      url: "/university",
      icon: icons.SchoolOutlinedIcon,
      breadcrumbs: true,      
    },
    {
      id: "college",
      title: "College",
      type: "item",
      url: "/college",
      icon: icons.BookOutlinedIcon,
      breadcrumbs: true,      
    },
    {
      id: "faculty",
      title: "Faculty",
      type: "item",
      url: "/faculty",
      icon: icons.ApartmentOutlinedIcon,
      breadcrumbs: true,      
    }   
  ]
};

export default pages;