import { appConstants } from "redux/constants";
import { sessionConstants } from "redux/constants";
import Axios from "utils/axios";

export const manageSession = (id=null, form) => {
  return async dispatch => {
    try{
      dispatch({ type: sessionConstants.MANAGE_SESSION_REQUEST });      
      let _data
      if(id){
        _data = await Axios.put(`/session/${id}`, form);
      }else{
        _data = await Axios.post(`/session`, form);
      }   
      const { data } = _data;   
      dispatch({ 
        type: sessionConstants.MANAGE_SESSION_SUCCESS,
        payload: { message: data?.message }
      });           
      if(data?.data){
        dispatch({ 
          type: appConstants.UPDATE_SESSION,
          payload:  data?.data
        });
      }
    }catch(error){
      dispatch({ 
        type: sessionConstants.MANAGE_SESSION_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getSession = (id) => {
  return async dispatch => {
    try{
      dispatch({ type: sessionConstants.GET_SESSION_REQUEST });
      const { data } = await Axios.get(`/session/${id}`);
      dispatch({ 
        type: sessionConstants.GET_SESSION_SUCCESS,
        payload: { session: data?.data }
      });
    }catch(error){
      dispatch({ 
        type: sessionConstants.GET_SESSION_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}