import { combineReducers } from "redux";

import menuReducer from "./menu.reducer";
import userReducer from "./user.reducer";
import appReducer from "./app.reducer";
import universityReducer from "./university.reducer";
import collegeReducer from "./college.reducer";
import facultyReducer from "./faculty.reducer";
import courseReducer from "./course.reducer";
import subjectReducer from "./subject.reducer";
import examReducer from "./exam.reducer";
import sessionReducer from "./session.reducer";
import admissionReducer from "./admission.reducer";
import formReducer from "./form.reducer";

const rootReducer = combineReducers({
  admission: admissionReducer,
  app: appReducer,
  college: collegeReducer,
  course: courseReducer,
  exam: examReducer,
  faculty: facultyReducer,
  form: formReducer,
  menu: menuReducer,
  session: sessionReducer,
  subject: subjectReducer,
  university: universityReducer,
  user: userReducer,
});

export default rootReducer;