import { lazy, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";

import Loadable from "components/Loadable";
import MainLayout from "layout/MainLayout";
import { appConstants } from "redux/constants";

const Dashboard = Loadable(lazy(() => import("pages/dashboard")));

const University = Loadable(lazy(() => import("pages/university")));
const AddUniversity = Loadable(lazy(() => import("pages/university/add-university")));
const EditUniversity = Loadable(lazy(() => import("pages/university/edit-university")));

const College = Loadable(lazy(() => import("pages/college")));
const AddCollege = Loadable(lazy(() => import("pages/college/add-college")));
const EditCollege = Loadable(lazy(() => import("pages/college/edit-college")));
const ManageCollege = Loadable(lazy(() => import("pages/college/manage-college")));
const Library = Loadable(lazy(() => import("pages/college/library")));

const Faculty = Loadable(lazy(() => import("pages/faculty")));
const AddFaculty = Loadable(lazy(() => import("pages/faculty/add-faculty")));
const EditFaculty = Loadable(lazy(() => import("pages/faculty/edit-faculty")));

const Course = Loadable(lazy(() => import("pages/course")));
const AddCourse = Loadable(lazy(() => import("pages/course/add-course")));
const EditCourse = Loadable(lazy(() => import("pages/course/edit-course")));
const CourseType = Loadable(lazy(() => import("pages/course/course-type")));
const AddCourseType = Loadable(lazy(() => import("pages/course/add-course-type")));
const EditCourseType = Loadable(lazy(() => import("pages/course/edit-course-type")));
const ManageClass = Loadable(lazy(() => import("pages/course/manage-class")));

const Subject = Loadable(lazy(() => import("pages/subject")));
const AddSubject = Loadable(lazy(() => import("pages/subject/add-subject")));
const EditSubject = Loadable(lazy(() => import("pages/subject/edit-subject")));

const ExamType = Loadable(lazy(() => import("pages/exam/exam-type")));
const AddExamType = Loadable(lazy(() => import("pages/exam/add-exam-type")));
const EditExamType = Loadable(lazy(() => import("pages/exam/edit-exam-type")));

const Session = Loadable(lazy(() => import("pages/session")));
const AddSession = Loadable(lazy(() => import("pages/session/add-session")));
const EditSession = Loadable(lazy(() => import("pages/session/edit-session")));

const StudentCategory = Loadable(lazy(() => import("pages/student-category")));
const AddStudentCategory = Loadable(lazy(() => import("pages/student-category/add-student-category")));
const EditStudentCategory = Loadable(lazy(() => import("pages/student-category/edit-student-category")));

const Admission = Loadable(lazy(() => import("pages/admission")));
const AddAdmission = Loadable(lazy(() => import("pages/admission/add-admission")));
const EditAdmission = Loadable(lazy(() => import("pages/admission/edit-admission")));

const AdmissionForm = Loadable(lazy(() => import("pages/form/admission")));
const ExamForm = Loadable(lazy(() => import("pages/form/exam")));
const Profile = Loadable(lazy(() => import("pages/profile")));

const PrivateRoute = ({ children }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { redirect } = useSelector(state => state.app);
  const { userLoading, isAuthenticated } = useSelector(state => state.user);

  useEffect(() => {
    if (redirect) {
      navigate(redirect);
      dispatch({
        type: appConstants.SET_REDIRECT,
        payload: ""
      });
    }
  }, [redirect, navigate, dispatch]);

  return !userLoading && 
    (!isAuthenticated ? 
      <Navigate to={`/auth/login?redirect=${encodeURIComponent(window.location.pathname)}`} />
    : children); 
};

const MainRoutes = {
  path: "/",
  element: <PrivateRoute><MainLayout/></PrivateRoute>,
  children: [
    {
      path: "/",
      element: <Dashboard/>
    },
    {
      path: "university",      
      children: [
        {
          path: "",
          element: <University/>
        },
        {
          path: "add",
          element: <AddUniversity/>
        },
        {
          path: "edit/:id",
          element: <EditUniversity/>
        }
      ]
    }, 
    {
      path: "college",      
      children: [
        {
          path: "",
          element: <College/>
        },
        {
          path: "add",
          element: <AddCollege/>
        },
        {
          path: "edit/:id",
          element: <EditCollege/>
        },
        {
          path: ":id/:module",
          element: <ManageCollege/>
        },
        {
          path: ":id/library/:libraryId",
          element: <Library/>
        }
      ]
    }, 
    {
      path: "faculty",      
      children: [
        {
          path: "",
          element: <Faculty/>
        },
        {
          path: "add",
          element: <AddFaculty/>
        },
        {
          path: "edit/:id",
          element: <EditFaculty/>
        }
      ]
    }, 
    {
      path: "course",      
      children: [
        {
          path: "",
          element: <Course/>
        },
        {
          path: "add",
          element: <AddCourse/>
        },
        {
          path: "edit/:id",
          element: <EditCourse/>
        },
        {
          path: "type",
          element: <CourseType/>
        },
        {
          path: "type/add",
          element: <AddCourseType/>
        },
        {
          path: "type/edit/:id",
          element: <EditCourseType/>
        },
        {
          path: "class/:id",
          element: <ManageClass/>
        }
      ]
    },  
    {
      path: "subject",      
      children: [
        {
          path: "",
          element: <Subject/>
        },
        {
          path: "add",
          element: <AddSubject/>
        },
        {
          path: "edit/:id",
          element: <EditSubject/>
        }
      ]
    },  
    {
      path: "exam",      
      children: [
        {
          path: "type",
          element: <ExamType/>
        },
        {
          path: "type/add",
          element: <AddExamType/>
        },
        {
          path: "type/edit/:id",
          element: <EditExamType/>
        }
      ]
    },  
    {
      path: "session",      
      children: [
        {
          path: "",
          element: <Session/>
        },
        {
          path: "add",
          element: <AddSession/>
        },
        {
          path: "edit/:id",
          element: <EditSession/>
        }
      ]
    },
    {
      path: "student-category",      
      children: [
        {
          path: "",
          element: <StudentCategory/>
        },
        {
          path: "add",
          element: <AddStudentCategory/>
        },
        {
          path: "edit/:id",
          element: <EditStudentCategory/>
        }
      ]
    },
    {
      path: "admission",      
      children: [
        {
          path: "",
          element: <Admission/>
        },
        {
          path: "add",
          element: <AddAdmission/>
        },
        {
          path: "edit/:id",
          element: <EditAdmission/>
        }
      ]
    },
    {
      path: "form",      
      children: [
        {
          path: "admission/:id",
          element: <AdmissionForm/>
        },
        {
          path: "exam/:id",
          element: <ExamForm/>
        }        
      ]
    }, 
    {
      path: "profile",
      element: <Profile/>
    }   
  ]
};

export default MainRoutes;