import { appConstants } from "redux/constants";
import { examConstants } from "redux/constants";
import Axios from "utils/axios";

export const manageExamType = (id=null, form) => {
  return async dispatch => {
    try{
      dispatch({ type: examConstants.MANAGE_EXAM_TYPE_REQUEST });
      let _data;
      if(id){
        _data = await Axios.put(`/exam/type/${id}`, form);
      }else{
        _data = await Axios.post(`/exam/type`, form);
      }      
      const { data } = _data;
      dispatch({ 
        type: examConstants.MANAGE_EXAM_TYPE_SUCCESS,
        payload: { message: data?.message }
      });           
      if(data?.data){
        dispatch({ 
          type: appConstants.UPDATE_EXAM_TYPE,
          payload:  data?.data          
        });
      }
    }catch(error){
      dispatch({ 
        type: examConstants.MANAGE_EXAM_TYPE_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getExamType = (id) => {
  return async dispatch => {
    try{
      dispatch({ type: examConstants.GET_EXAM_TYPE_REQUEST });
      const { data } = await Axios.get(`/exam/type/${id}`);
      dispatch({ 
        type: examConstants.GET_EXAM_TYPE_SUCCESS,
        payload: { examType: data?.data }
      });
    }catch(error){
      dispatch({ 
        type: examConstants.GET_EXAM_TYPE_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}