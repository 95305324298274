import { universityConstants } from "redux/constants";

const initState = {
  loading: false,
  error: null,
  message: null,  
  university: null,
};

const universityReducer = (state = initState, action) => {  
  switch (action.type) {    
    case universityConstants.MANAGE_UNIVERSITY_REQUEST:
    case universityConstants.GET_UNIVERSITY_REQUEST:    
      return {
        ...state,
        loading: true,
      };
      
    case universityConstants.MANAGE_UNIVERSITY_SUCCESS:    
      return {
        ...state,
        loading: false,
        message: action.payload.message,
      };

    case universityConstants.GET_UNIVERSITY_SUCCESS:
      return {
        ...state,
        loading: false,        
        university: action.payload.university,
      };

    case universityConstants.MANAGE_UNIVERSITY_FAILURE:
    case universityConstants.GET_UNIVERSITY_FAILURE:    
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case universityConstants.RESET_ERROR_AND_MESSAGE:
      return{
        ...state,
        error: null,
        message: null        
      } 
      
    case universityConstants.RESET:
      return {
        ...initState,        
      }
   
    default:
      return state;
  }
};

export default universityReducer;