import { appConstants } from "redux/constants";

const initState = {
  loading: false,
  error: null,
  message: null,
  universities: [],
  university: null,   
  faculties: [],
  courseTypes: [],   
  examTypes: [],
  sessions: [],
  studentCategories: [],
  studentCategory: null,
  redirect: ""
};

const appReducer = (state = initState, action) => {
  let temp = null;
  switch (action.type) {    
    case appConstants.GET_INITIAL_DATA_REQUEST:
    case appConstants.GET_FACULTIES_REQUEST:
    case appConstants.MANAGE_STUDENT_CATEGORY_REQUEST:
    case appConstants.GET_STUDENT_CATEGORY_REQUEST:
    case appConstants.UPDATE_PASSWORD_REQUEST:
    case appConstants.UPDATE_AVATAR_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case appConstants.SET_REDIRECT:
      return {
        ...state,
        redirect: action.payload
      }

    case appConstants.GET_INITIAL_DATA_SUCCESS:
      return {
        ...state,
        universities: action.payload.universities,
        university: action.payload.universities[0]?.id,                
        faculties: action.payload.faculties,
        courseTypes: action.payload.courseTypes,        
        examTypes: action.payload.examTypes,
        sessions: action.payload.sessions,
        studentCategories: action.payload.studentCategories,
        loading: false,
      };

    case appConstants.GET_FACULTIES_SUCCESS:
      return {
        ...state,
        faculties: action.payload.faculties,
        loading: false,
      };

    case appConstants.MANAGE_STUDENT_CATEGORY_SUCCESS:
      temp = [...state.studentCategories];
      const studentCategoryIndex = temp.findIndex(studentCategory => studentCategory.id === action.payload.studentCategory.id);
      if(studentCategoryIndex > -1){
        temp[studentCategoryIndex] = action.payload.studentCategory;
      }else{
        temp.push(action.payload.studentCategory);
      }
      return {
        ...state,
        studentCategories: temp,
        message: action.payload.message,
        loading: false,
      };  
      
    case appConstants.GET_STUDENT_CATEGORY_SUCCESS:
      return {
        ...state,
        studentCategory: action.payload,
        loading: false,
      };

    case appConstants.UPDATE_PASSWORD_SUCCESS:
    case appConstants.UPDATE_AVATAR_SUCCESS:
      return {
        ...state,
        message: action.payload?.message,
        loading: false,
      };

    case appConstants.GET_INITIAL_DATA_FAILURE:
    case appConstants.GET_FACULTIES_FAILURE:
    case appConstants.MANAGE_STUDENT_CATEGORY_FAILURE:
    case appConstants.GET_STUDENT_CATEGORY_FAILURE:
    case appConstants.UPDATE_PASSWORD_FAILURE:
    case appConstants.UPDATE_AVATAR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case appConstants.UPDATE_COLLEGE_IN_UNIVERSITY:
      temp = [...state.universities];
      const index = temp.findIndex(university => university.id === action.payload.universityId);
      let collegeIndex = temp[index].colleges.findIndex(college => college.id === action.payload.college.id);
      if(collegeIndex > -1){
        temp[index].colleges[collegeIndex] = action.payload.college;
      }else{
        temp[index].colleges.push(action.payload.college);
      }
      return {
        ...state,
        universities: temp,
      };

    case appConstants.SELECT_UNIVERSITY:
      return {
        ...state,
        university: action.payload,
      };     
      
    case appConstants.UPDATE_COURSE_TYPE:
      temp = [...state.courseTypes];      
      const typeIndex = temp?.findIndex(courseType => courseType.id === action.payload.id);      
      if(typeIndex > -1){
        temp[typeIndex] = action.payload
      }else{
        temp.push(action.payload)
      }
      return {
        ...state,
        courseTypes: temp,
      };

    case appConstants.UPDATE_COURSE:
      temp = [...state.courses];      
      const courseIndex = temp?.findIndex(course => course.id === action.payload.id);      
      if(courseIndex > -1){
        temp[courseIndex] = action.payload
      }else{
        temp.push(action.payload)
      }
      return {
        ...state,
        courses: temp,
      };

    case appConstants.UPDATE_SUBJECT:
      temp = [...state.subjects];      
      const subjectIndex = temp?.findIndex(subject => subject?.id === action.payload?.id);      
      if(subjectIndex > -1){
        temp[subjectIndex] = action.payload
      }else{
        temp.push(action?.payload)
      }
      return {
        ...state,
        subjects: temp,
      };

    case appConstants.UPDATE_EXAM_TYPE:
      temp = [...state.examTypes];      
      const examTypeIndex = temp?.findIndex(examType => examType.id === action.payload.id);      
      if(examTypeIndex > -1){
        temp[examTypeIndex] = action.payload
      }else{
        temp.push(action.payload)
      }
      return {
        ...state,
        examTypes: temp,
      };

    case appConstants.UPDATE_SESSION:
      temp = [...state.sessions];      
      if(action.payload.isCurrent){
        temp = temp.map(session => {
          session.isCurrent = false;
          return session;
        })
      }
      const sessionIndex = temp?.findIndex(session => session.id === action.payload.id);      
      if(sessionIndex > -1){
        temp[sessionIndex] = action.payload
      }else{
        temp.push(action.payload)
      }      
      return {
        ...state,
        sessions: temp,
      };

    case appConstants.RESET_ERROR_AND_MESSAGE:
      return{
        ...state,
        error: null,
        message: null        
      } 
      
    case appConstants.RESET:
      return {
        ...initState,        
      }

    default:
      return state;
  }
};

export default appReducer;