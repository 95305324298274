import { collegeConstants } from "redux/constants";

const initState = {
  loading: false,
  error: null,
  message: null, 
  college: null,
  library: null,
  students: null 
};

const collegeReducer = (state = initState, action) => {
  let temp = null;
  switch (action.type) {     
    case collegeConstants.MANAGE_COLLEGE_REQUEST:
    case collegeConstants.GET_COLLEGE_REQUEST:    
    case collegeConstants.ADD_COLLEGE_DOCUMENT_REQUEST:
    case collegeConstants.MANAGE_COLLEGE_ADDONS_REQUEST:    
    case collegeConstants.CREATE_COLLEGE_USER_REQUEST:
    case collegeConstants.MANAGE_COLLEGE_LIBRARY_REQUEST:
    case collegeConstants.GET_LIBRARY_REQUEST:
    case collegeConstants.CREATE_LIBRARY_USER_REQUEST:
    case collegeConstants.UPDATE_LIBRARY_USER_REQUEST:
    case collegeConstants.GET_COLLEGE_STUDENTS_REQUEST:
      return state = {
        ...state,
        loading: true
      }

    case collegeConstants.GET_COLLEGE_SUCCESS:
      return state = {
        ...state,
        loading: false,
        college: action.payload.college
      }        
        
    case collegeConstants.MANAGE_COLLEGE_SUCCESS:    
    case collegeConstants.MANAGE_COLLEGE_ADDONS_SUCCESS:
    case collegeConstants.CREATE_COLLEGE_USER_SUCCESS:    
    case collegeConstants.MANAGE_COLLEGE_LIBRARY_SUCCESS: 
    case collegeConstants.CREATE_LIBRARY_USER_SUCCESS:
    case collegeConstants.UPDATE_LIBRARY_USER_SUCCESS:       
      return state = {
        ...state,
        loading: false,
        message: action.payload.message
      }    

    case collegeConstants.ADD_COLLEGE_DOCUMENT_SUCCESS:
      temp = state.college;
      let doc = action.payload?.document;
      if(doc && temp?.id === doc?.collegeId){
        temp.collegeDocuments = [...temp.collegeDocuments, doc];
      }      
      return state = {
        ...state,
        loading: false,
        college: temp,
        message: action.payload.message
      }

    case collegeConstants.GET_LIBRARY_SUCCESS:
      return state = {
        ...state,
        library: action.payload,
        loading: false,
      }

    case collegeConstants.GET_COLLEGE_STUDENTS_SUCCESS:
      return state = {
        ...state,
        students: action.payload,
        loading: false,
      }

    case collegeConstants.MANAGE_COLLEGE_FAILURE:
    case collegeConstants.GET_COLLEGE_FAILURE:    
    case collegeConstants.ADD_COLLEGE_DOCUMENT_FAILURE:
    case collegeConstants.MANAGE_COLLEGE_ADDONS_FAILURE:    
    case collegeConstants.CREATE_COLLEGE_USER_FAILURE:
    case collegeConstants.MANAGE_COLLEGE_LIBRARY_FAILURE:
    case collegeConstants.GET_LIBRARY_FAILURE:
    case collegeConstants.CREATE_LIBRARY_USER_FAILURE:
    case collegeConstants.UPDATE_LIBRARY_USER_FAILURE:
    case collegeConstants.GET_COLLEGE_STUDENTS_FAILURE:
      return state = {
        ...state,
        loading: false,
        error: action.payload
      }

    case collegeConstants.RESET_ERROR_AND_MESSAGE:
      return{
        ...state,
        error: null,
        message: null        
      } 
      
    case collegeConstants.RESET:
      return {
        ...initState,        
      }
      
    default:
      return state;
  }
};

export default collegeReducer;