import { sessionConstants } from "redux/constants";

const initState = {
  loading: false,
  error: null,
  message: null, 
  session: null,
};

const sessionReducer = (state = initState, action) => {  
  switch (action.type) {     
    case sessionConstants.MANAGE_SESSION_REQUEST:
    case sessionConstants.GET_SESSION_REQUEST:    
      return state = {
        ...state,
        loading: true
      }

    case sessionConstants.GET_SESSION_SUCCESS:
      return state = {
        ...state,
        loading: false,
        session: action.payload.session
      }
        
        
    case sessionConstants.MANAGE_SESSION_SUCCESS:      
      return state = {
        ...state,
        loading: false,
        message: action.payload.message
      }

    case sessionConstants.MANAGE_SESSION_FAILURE:
    case sessionConstants.GET_SESSION_FAILURE:
      return state = {
        ...state,
        loading: false,
        error: action.payload
      }

    case sessionConstants.RESET_ERROR_AND_MESSAGE:
      return{
        ...state,
        error: null,
        message: null        
      } 
      
    case sessionConstants.RESET:
      return {
        ...initState,        
      }
      
    default:
      return state;
  }
};

export default sessionReducer;