import { appConstants, courseConstants } from "redux/constants";
import Axios from "utils/axios";

export const manageCourseType = (id=null, form) => {
  return async dispatch => {
    try{
      dispatch({ type: courseConstants.MANAGE_COURSE_TYPE_REQUEST });  
      let _data;
      if(id){
        _data = await Axios.put(`/course/type/${id}`, form);
      }else{
        _data = await Axios.post(`/course/type`, form);
      }
      const { data } = _data;      
      dispatch({ 
        type: courseConstants.MANAGE_COURSE_TYPE_SUCCESS,
        payload: { message: data?.message } 
      });     
      if(data?.data){
        dispatch({ 
          type: appConstants.UPDATE_COURSE_TYPE, 
          payload: data?.data
        });
      }
    }catch(error){
      dispatch({ 
        type: courseConstants.MANAGE_COURSE_TYPE_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getCourseType = (id) => {
  return async dispatch => {
    try{
      dispatch({ type: courseConstants.GET_COURSE_TYPE_REQUEST });      
      const { data } = await Axios.get(`/course/type/${id}`);
      dispatch({ 
        type: courseConstants.GET_COURSE_TYPE_SUCCESS,
        payload: { courseType: data?.data } 
      });     
    }catch(error){
      dispatch({ 
        type: courseConstants.GET_COURSE_TYPE_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getCourse = (id) => {
  return async dispatch => {
    try{
      dispatch({ type: courseConstants.GET_COURSE_REQUEST });      
      const { data } = await Axios.get(`/course/${id}`);
      dispatch({ 
        type: courseConstants.GET_COURSE_SUCCESS,
        payload: { course: data?.data } 
      });     
    }catch(error){
      dispatch({ 
        type: courseConstants.GET_COURSE_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const manageCourse = (id=null, form) => {
  return async dispatch => {
    try{
      dispatch({ type: courseConstants.MANAGE_COURSE_REQUEST });      
      let _data;
      if(id){
        _data = await Axios.put(`/course/${id}`, form);
      }else{
        _data = await Axios.post(`/course`, form);
      }
      const { data } = _data;
      dispatch({ 
        type: courseConstants.MANAGE_COURSE_SUCCESS,
        payload: { message: data?.message } 
      });   
      dispatch({
        type: appConstants.UPDATE_COURSE,
        payload: data?.data
      })
      if(id) dispatch(getCourse(id));  
    }catch(error){
      dispatch({ 
        type: courseConstants.MANAGE_COURSE_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const addCourseDocument = (form) => {
  return async dispatch => {
    try{
      dispatch({ type: courseConstants.ADD_COURSE_DOCUMENT_REQUEST });      
      const { data } = await Axios.post(`/course/document`, form);
      dispatch({ 
        type: courseConstants.ADD_COURSE_DOCUMENT_SUCCESS,
        payload: { message: data?.message, document: data?.data } 
      });     
    }catch(error){
      dispatch({ 
        type: courseConstants.ADD_COURSE_DOCUMENT_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getCourseClass = (id) => {
  return async dispatch => {
    try{
      dispatch({ type: courseConstants.GET_COURSE_CLASS_REQUEST });      
      const { data } = await Axios.get(`/course/class/${id}`);
      dispatch({ 
        type: courseConstants.GET_COURSE_CLASS_SUCCESS,
        payload: { courseClass: data?.data } 
      });     
    }catch(error){
      dispatch({ 
        type: courseConstants.GET_COURSE_CLASS_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const updateCourseClass = (id, form) => {
  return async dispatch => {
    try{
      dispatch({ type: courseConstants.UPDATE_COURSE_CLASS_REQUEST });      
      const { data } = await Axios.put(`/course/class/${id}`, form);
      dispatch({ 
        type: courseConstants.UPDATE_COURSE_CLASS_SUCCESS,
        payload: { message: data?.message } 
      });     
    }catch(error){
      dispatch({ 
        type: courseConstants.UPDATE_COURSE_CLASS_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const manageSubjectInCourseClass = (id=null, form) => {
  return async dispatch => {
    try{
      dispatch({ type: courseConstants.MANAGE_SUBJECT_IN_COURSE_CLASS_REQUEST });      
      if(id){
        const { data } = await Axios.put(`/course/class/subject/${id}`, form);
        dispatch({ 
          type: courseConstants.MANAGE_SUBJECT_IN_COURSE_CLASS_SUCCESS,
          payload: { message: data?.message } 
        });
      }else{
        const { data } = await Axios.post(`/course/class/subject`, form);
        dispatch({ 
          type: courseConstants.MANAGE_SUBJECT_IN_COURSE_CLASS_SUCCESS,
          payload: { message: data?.message } 
        });
      }
      dispatch(getCourseClass(form?.courseClassId));      
    }catch(error){      
      dispatch({ 
        type: courseConstants.UPDATE_COURSE_CLASS_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const manageSubjectMarks = (id=null, form) => {
  return async dispatch => {
    try{
      dispatch({ type: courseConstants.MANAGE_SUBJECT_MARKS_REQUEST });      
      if(id){
        const { data } = await Axios.put(`/course/class/subject/marks/${id}`, form);
        dispatch({ 
          type: courseConstants.MANAGE_SUBJECT_MARKS_SUCCESS,
          payload: { message: data?.message, marks: data?.data } 
        });
      }else{
        const { data } = await Axios.post(`/course/class/subject/marks`, form);
        dispatch({ 
          type: courseConstants.MANAGE_SUBJECT_MARKS_SUCCESS,
          payload: { message: data?.message, marks: data?.data } 
        });
      }      
    }catch(error){      
      dispatch({ 
        type: courseConstants.MANAGE_SUBJECT_MARKS_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getCourses = (filter) => {
  return async dispatch => {
    try{
      dispatch({ type: courseConstants.GET_COURSES_REQUEST });
      const { query, page, limit } = filter;
      let URL = `/course?limit=${limit}&page=${page}`;
      if(query) URL += `&query=${query}`;
      const { data } = await Axios.get(URL);
      dispatch({ 
        type: courseConstants.GET_COURSES_SUCCESS,
        payload: data?.data
      });
    }catch(error){
      dispatch({ 
        type: courseConstants.GET_COURSES_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}