import { universityConstants } from "redux/constants";
import Axios from "utils/axios";

export const manageUniversity = (id=null, form) => {
  return async dispatch => {
    try{
      dispatch({ type: universityConstants.MANAGE_UNIVERSITY_REQUEST });      
      let _data;
      if(id){
        _data = await Axios.put(`/university/${id}`, form);
      }else{
        _data = await Axios.post(`/university`, form);
      }
      const { data } = _data;      
      dispatch({ 
        type: universityConstants.MANAGE_UNIVERSITY_SUCCESS,
        payload: { message: data.message } 
      });
    }catch(error){
      dispatch({ 
        type: universityConstants.MANAGE_UNIVERSITY_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getUniversity = (id) => {
  return async dispatch => {
    try{
      dispatch({ type: universityConstants.GET_UNIVERSITY_REQUEST });      
      const { data } = await Axios.get(`/university/${id}`);            
      dispatch({ 
        type: universityConstants.GET_UNIVERSITY_SUCCESS,
        payload: { university: data.data } 
      });     
    }catch(error){
      dispatch({ 
        type: universityConstants.GET_UNIVERSITY_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const updateUniversity = (id, form) => {
  return async dispatch => {
    try{
      dispatch({ type: universityConstants.UPDATE_UNIVERSITY_REQUEST });      
      const { data } = await Axios.put(`/university/${id}`, form);            
      dispatch({ 
        type: universityConstants.UPDATE_UNIVERSITY_SUCCESS,
        payload: { message: data.message } 
      });
    }catch(error){
      dispatch({ 
        type: universityConstants.UPDATE_UNIVERSITY_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}