import { admissionConstants } from "redux/constants";
import Axios from "utils/axios";

export const manageAdmission = (id=null, form) => {
  return async dispatch => {
    try{
      dispatch({ type: admissionConstants.MANAGE_ADMISSION_REQUEST });      
      let _data;
      if(id){
        _data = await Axios.put(`/admin/admission/${id}`, form);
      }else{
        _data = await Axios.post(`/admin/admission`, form);
      }
      const { data } = _data;      
      dispatch({ 
        type: admissionConstants.MANAGE_ADMISSION_SUCCESS,
        payload: { message: data?.message } 
      });           
    }catch(error){
      dispatch({ 
        type: admissionConstants.MANAGE_ADMISSION_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getAdmissions = (page=1, limit=10, query) => {
  return async dispatch => {
    try{
      dispatch({ type: admissionConstants.GET_ADMISSIONS_REQUEST });
      let URL = `/admin/admission?page=${page}&limit=${limit}`;
      if(query) URL = URL + `&${query}`;
      const { data } = await Axios.get(URL);      
      dispatch({ 
        type: admissionConstants.GET_ADMISSIONS_SUCCESS,
        payload: data?.data
      });
    }catch(error){
      dispatch({ 
        type: admissionConstants.GET_ADMISSIONS_FAILURE,
        payload: error.response.data.error 
      });
    }
  } 
}

export const getAdmission = (id) => {
  return async dispatch => {
    try{
      dispatch({ type: admissionConstants.GET_ADMISSION_REQUEST });
      const { data } = await Axios.get(`/admin/admission/${id}`);
      dispatch({ 
        type: admissionConstants.GET_ADMISSION_SUCCESS,
        payload: data?.data
      });
    }catch(error){
      dispatch({ 
        type: admissionConstants.GET_ADMISSION_FAILURE,
        payload: error.response.data.error 
      });
    }
  } 
}

export const manageAdmissionCourse = (id=null, form) => {
  return async dispatch => {
    try{
      dispatch({ type: admissionConstants.MANAGE_ADMISSION_COURSE_REQUEST });      
      let _data;
      if(id){
        _data = await Axios.put(`/admin/admission/course/${id}`, form);
      }else{
        _data = await Axios.post(`/admin/admission/course`, form);
      }
      const { data } = _data;      
      dispatch({ 
        type: admissionConstants.MANAGE_ADMISSION_COURSE_SUCCESS,
        payload: { message: data?.message, course: data?.data } 
      });           
    }catch(error){
      dispatch({ 
        type: admissionConstants.MANAGE_ADMISSION_COURSE_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const manageAdmissionCourseFee = (id=null, form) => {
  return async dispatch => {
    try{
      dispatch({ type: admissionConstants.MANAGE_ADMISSION_COURSE_FEE_REQUEST });      
      let _data;
      if(id){
        _data = await Axios.put(`/admin/admission/course/fee/${id}`, form);
      }else{
        _data = await Axios.post(`/admin/admission/course/fee`, form);
      }
      const { data } = _data;      
      dispatch({ 
        type: admissionConstants.MANAGE_ADMISSION_COURSE_FEE_SUCCESS,
        payload: { message: data?.message, fee: data?.data } 
      });           
    }catch(error){
      dispatch({ 
        type: admissionConstants.MANAGE_ADMISSION_COURSE_FEE_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}