import { appConstants } from "redux/constants";
import Axios from "utils/axios";
import { loadUser } from "./user.action";

// function createNestedFaculties(faculties, parentId=null){
//   let facultyList = [];
//   let faculty;
//   if(parentId === null){
//     faculty = faculties.filter(item => item.parentFacultyId === null);
//   }else{
//     faculty = faculties.filter(item => item.parentFacultyId === parentId);
//   }

//   for(let f of faculty){
//     facultyList.push({
//       ...f,
//       children: createNestedFaculties(faculties, f.id)
//     });
//   }
  
//   return facultyList;
// }

export const getInitialData = (form) => {
  return async dispatch => {
    try{
      dispatch({ type: appConstants.GET_INITIAL_DATA_REQUEST });      
      const { data } = await Axios.get(`/admin/initial-data`);            
      dispatch({ 
        type: appConstants.GET_INITIAL_DATA_SUCCESS,
        payload: { ...data?.data }
      });
    }catch(error){
      dispatch({ 
        type: appConstants.GET_INITIAL_DATA_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getFaculties = () => {
  return async dispatch => {
    try{
      dispatch({ type: appConstants.GET_FACULTIES_REQUEST });
      const { data } = await Axios.get(`/faculty`);
      dispatch({ 
        type: appConstants.GET_FACULTIES_SUCCESS,
        payload: { faculties: data?.data } 
      });
    }catch(error){
      dispatch({ 
        type: appConstants.GET_FACULTIES_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const manageStudentCategory = (id=null, form) => {
  return async dispatch => {
    try{
      dispatch({ type: appConstants.MANAGE_STUDENT_CATEGORY_REQUEST });
      let _data;
      if(id){
        _data = await Axios.put(`/app/student-category/${id}`, form);
      }else{
        _data = await Axios.post(`/app/student-category`, form);
      }
      const { data } = _data;      
      dispatch({ 
        type: appConstants.MANAGE_STUDENT_CATEGORY_SUCCESS,
        payload: { studentCategory: data?.data, message: data?.message } 
      });
    }catch(error){
      dispatch({ 
        type: appConstants.MANAGE_STUDENT_CATEGORY_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getStudentCategory = (id) => {
  return async dispatch => {
    try{
      dispatch({ type: appConstants.GET_STUDENT_CATEGORY_REQUEST });
      const { data } = await Axios.get(`/app/student-category/${id}`);
      dispatch({ 
        type: appConstants.GET_STUDENT_CATEGORY_SUCCESS,
        payload: data?.data
      });
    }catch(error){
      dispatch({ 
        type: appConstants.GET_STUDENT_CATEGORY_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const updatePassword = (form) => {
  return async dispatch => {
    try{
      dispatch({ type: appConstants.UPDATE_PASSWORD_REQUEST });
      const { data } = await Axios.put(`/auth/admin/password`, form);            
      dispatch({ 
        type: appConstants.UPDATE_PASSWORD_SUCCESS,
        payload: { 
          message: data?.message,
        }
      });
    }catch(error){
      dispatch({ 
        type: appConstants.UPDATE_PASSWORD_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const updateAvatar = (form) => {
  return async dispatch => {
    try{
      dispatch({ type: appConstants.UPDATE_AVATAR_REQUEST });
      const { data } = await Axios.put(`/auth/admin/avatar`, form);            
      dispatch({ 
        type: appConstants.UPDATE_AVATAR_SUCCESS,
        payload: { 
          message: data?.message,
        }
      });
      dispatch(loadUser());
    }catch(error){
      dispatch({ 
        type: appConstants.UPDATE_AVATAR_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}