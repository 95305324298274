import { admissionConstants } from "redux/constants";

const initState = {
  loading: false,
  error: null,
  message: null, 
  admissions: [],
  total: 0,
  page: 1,
  limit: 10,
  admission: null
};

const admissionReducer = (state = initState, action) => {  
  let temp = null;
  switch (action.type) {     
    case admissionConstants.MANAGE_ADMISSION_REQUEST:    
    case admissionConstants.GET_ADMISSIONS_REQUEST:
    case admissionConstants.GET_ADMISSION_REQUEST:
    case admissionConstants.MANAGE_ADMISSION_COURSE_REQUEST:
    case admissionConstants.MANAGE_ADMISSION_COURSE_FEE_REQUEST:
      return state = {
        ...state,
        loading: true
      }

    case admissionConstants.MANAGE_ADMISSION_SUCCESS:      
      return state = {
        ...state,
        loading: false,        
        message: action.payload.message
      }

    case admissionConstants.GET_ADMISSIONS_SUCCESS:
      return state = {
        ...state,
        loading: false,
        admissions: action.payload.admissions,
        total: action.payload.total,
        page: action.payload.page,
        limit: action.payload.limit
      }
                
    case admissionConstants.GET_ADMISSION_SUCCESS:       
      return state = {
        ...state,
        loading: false,
        admission: action.payload        
      }    

    case admissionConstants.MANAGE_ADMISSION_COURSE_SUCCESS:
      temp = state.admission;      
      const courseIndex = temp?.admissionCourses?.findIndex(course => course.id === action.payload.course.id);
      if(courseIndex > -1){
        temp.admissionCourses[courseIndex] = action.payload.course;
      }else{
        temp.admissionCourses.push(action.payload.course);
      }

      return state = {
        ...state,
        loading: false,
        admission: temp,
        message: action.payload.message
      }

    case admissionConstants.MANAGE_ADMISSION_COURSE_FEE_SUCCESS:
      temp = state.admission;      
      let course = temp?.admissionCourses?.find(course => course.id === action.payload.fee.admissionCourseId);
      let fee = course?.admissionCourseFee?.find(fee => fee.id === action.payload.fee.id);
      if(fee){
        course.admissionCourseFee = course.admissionCourseFee.map(f => {
          if(f.id === fee.id){
            return action.payload.fee;
          }
          return f;
        })
      }else{
        course.admissionCourseFee.push(action.payload.fee);
      }

      return state = {
        ...state,
        loading: false,
        admission: temp,
        message: action.payload.message
      }

    case admissionConstants.MANAGE_ADMISSION_FAILURE:
    case admissionConstants.GET_ADMISSIONS_FAILURE:
    case admissionConstants.GET_ADMISSION_FAILURE:
    case admissionConstants.MANAGE_ADMISSION_COURSE_FAILURE:
    case admissionConstants.MANAGE_ADMISSION_COURSE_FEE_FAILURE:
      return state = {
        ...state,
        loading: false,
        error: action.payload
      }

    case admissionConstants.RESET_ERROR_AND_MESSAGE:
      return{
        ...state,
        error: null,
        message: null        
      } 
      
    case admissionConstants.RESET:
      return {
        ...initState,        
      }
      
    default:
      return state;
  }
};

export default admissionReducer;