import { formConstants } from "redux/constants";

const initState = {
  loading: false,
  error: null,
  message: null, 
  admissionFormsData: null,
  admissionForm: null,
  examFormsData: null,
  examForm: null,  
};

const formReducer = (state = initState, action) => {  
  let temp = null;
  switch (action.type) {     
    case formConstants.GET_FORMS_REQUEST:
    case formConstants.GET_FORM_REQUEST:    
    case formConstants.MANAGE_FORM_REQUEST:
    case formConstants.SYNC_FORM_COURSES_REQUEST:
    case formConstants.MANAGE_FORM_COURSE_REQUEST:
    case formConstants.MANAGE_FORM_COURSE_FEE_REQUEST:    
      return state = {
        ...state,
        loading: true
      }

    case formConstants.GET_ADMISSION_FORMS_SUCCESS:
      return state = {
        ...state,
        admissionFormsData: action.payload,
        loading: false,
      }

    case formConstants.GET_ADMISSION_FORM_SUCCESS:
      return state = {
        ...state,
        admissionForm: action.payload,        
        loading: false,        
      }

    case formConstants.MANAGE_ADMISSION_FORM_SUCCESS:
      temp = state.admissionFormsData;      
      const formIndex = temp?.forms?.findIndex(form => form.id === action.payload.data.id);
      if(formIndex > -1){
        temp.forms[formIndex] = action.payload.data;
      }else{        
        temp.forms.unshift(action.payload.data);        
      }
      return state = {
        ...state,
        admissionFormsData: temp,
        message: action.payload.message,
        loading: false,
      }

    case formConstants.SYNC_FORM_COURSES_SUCCESS:            
    case formConstants.MANAGE_FORM_COURSE_SUCCESS:
    case formConstants.MANAGE_FORM_COURSE_FEE_SUCCESS:    
      return state = {
        ...state,        
        message: action.payload.message,
        loading: false,
      }

    case formConstants.GET_EXAM_FORMS_SUCCESS:
      return state = {
        ...state,
        examFormsData: action.payload,
        loading: false,
      }

    case formConstants.GET_EXAM_FORM_SUCCESS:
      return state = {
        ...state,
        examForm: action.payload,        
        loading: false,        
      }

    case formConstants.MANAGE_EXAM_FORM_SUCCESS:
      temp = state.examFormsData;      
      const examIndex = temp?.forms?.findIndex(form => form.id === action.payload?.data?.id);
      if(examIndex > -1){
        temp.forms[examIndex] = action.payload?.data;
      }else{        
        temp.forms.unshift(action.payload?.data);        
      }
      return state = {
        ...state,
        examFormsData: temp,
        message: action.payload.message,
        loading: false,
      }

    case formConstants.GET_FORMS_FAILURE:
    case formConstants.GET_FORM_FAILURE:    
    case formConstants.MANAGE_FORM_FAILURE:    
    case formConstants.SYNC_FORM_COURSES_FAILURE:
    case formConstants.MANAGE_FORM_COURSE_FAILURE:
    case formConstants.MANAGE_FORM_COURSE_FEE_FAILURE:    
      return state = {
        ...state,
        loading: false,
        error: action.payload
      }
    
    case formConstants.RESET_ERROR_AND_MESSAGE:
      return{
        ...state,
        error: null,
        message: null        
      } 
      
    case formConstants.RESET:
      return {
        ...initState,        
      }
      
    default:
      return state;
  }
};

export default formReducer;