import { appConstants, collegeConstants } from "redux/constants";
import Axios from "utils/axios";

export const manageCollege = (id=null, form) => {
  return async dispatch => {
    try{
      dispatch({ type: collegeConstants.MANAGE_COLLEGE_REQUEST });      
      let _data;
      if(id){
        _data = await Axios.put(`/college/${id}`, form);
      }else{
        _data = await Axios.post(`/college`, form);
      }
      const { data } = _data;      
      dispatch({ 
        type: collegeConstants.MANAGE_COLLEGE_SUCCESS,
        payload: { message: data?.message } 
      });     
      if(data?.data){
        dispatch({ 
          type: appConstants.UPDATE_COLLEGE_IN_UNIVERSITY, 
          payload: {
            college: {
              name: data?.data?.name,
              id: data?.data?.id,
              code: data?.data?.code,
            },
            universityId: data?.data?.universityId
          }
        });
      }
    }catch(error){
      dispatch({ 
        type: collegeConstants.MANAGE_COLLEGE_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getCollege = (id, query) => {
  return async dispatch => {
    try{
      dispatch({ type: collegeConstants.GET_COLLEGE_REQUEST });      
      let URL = `/college/${id}`;
      if(query) URL += query;
      const { data } = await Axios.get(URL);            
      dispatch({ 
        type: collegeConstants.GET_COLLEGE_SUCCESS,
        payload: { college: data?.data } 
      });     
    }catch(error){
      dispatch({ 
        type: collegeConstants.GET_COLLEGE_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const addCollegeDocument = (form) => {
  return async dispatch => {
    try{
      dispatch({ type: collegeConstants.ADD_COLLEGE_DOCUMENT_REQUEST });      
      const { data } = await Axios.post(`/college/document`, form);                  
      dispatch({ 
        type: collegeConstants.ADD_COLLEGE_DOCUMENT_SUCCESS,
        payload: {
          document: data?.data,
          message: data?.message
        }
      });
    }catch(error){
      dispatch({ 
        type: collegeConstants.ADD_COLLEGE_DOCUMENT_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const manageCollegeAddons = (form, module, edit=false) => {
  return async dispatch => {
    try{
      dispatch({ type: collegeConstants.MANAGE_COLLEGE_ADDONS_REQUEST });      
      let URL = ""
      if(module === "courses") URL = `/college/courses`;
      else if(module === "departments") URL = `/college/faculties`;
      else if(module === "users") URL = `/college/users`;
      if(URL){
        let _data;
        if(edit){
          _data = await Axios.put(URL, form);
        }else{
          _data = await Axios.post(URL, form);
        }
        const { data } = _data;        
        dispatch({ 
          type: collegeConstants.MANAGE_COLLEGE_ADDONS_SUCCESS,
          payload: { message: data?.message } 
        });
        function getUpdatedData(){
          let query = ""
          if (module === "courses") query = "?courses=true"
          else if (module === "departments") query = "?faculties=true"
          else if (module === "users") query = "?users=true"
          if(query) dispatch(getCollege(form?.collegeId, query));
        }
        if(!edit){
          getUpdatedData();
        }else if(module === "users"){
          getUpdatedData();
        }
      }else{
        dispatch({
          type: collegeConstants.MANAGE_COLLEGE_ADDONS_SUCCESS,
          payload: { message: "No module found" }
        })
      }      
    }catch(error){
      dispatch({ 
        type: collegeConstants.MANAGE_COLLEGE_ADDONS_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const createCollegeUser = (form) => {
  return async dispatch => {
    try{
      dispatch({ type: collegeConstants.CREATE_COLLEGE_USER_REQUEST });      
      const { data } = await Axios.post(`/auth/college/register`, form);            
      dispatch({ 
        type: collegeConstants.CREATE_COLLEGE_USER_SUCCESS,
        payload: { message: data?.message } 
      });     
      dispatch(getCollege(form?.collegeId, "?users=true"));
    }catch(error){
      dispatch({ 
        type: collegeConstants.CREATE_COLLEGE_USER_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const manageCollegeLibrary = (id=null, form, collegeId) => {
  return async dispatch => {
    try{
      dispatch({ type: collegeConstants.MANAGE_COLLEGE_LIBRARY_REQUEST });      
      let _data;
      if(id){
        _data = await Axios.put(`/college/library/${id}`, form);
      }else{
        _data = await Axios.post(`/college/library`, form);
      }
      const { data } = _data;      
      dispatch({ 
        type: collegeConstants.MANAGE_COLLEGE_LIBRARY_SUCCESS,
        payload: { message: data?.message } 
      });     
      dispatch(getCollege(collegeId, "?library=true"));
    }catch(error){
      dispatch({ 
        type: collegeConstants.MANAGE_COLLEGE_LIBRARY_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getLibrary = (id) => {
  return async dispatch => {
    try{
      dispatch({ type: collegeConstants.GET_LIBRARY_REQUEST });      
      const { data } = await Axios.get(`/college/library/${id}`);            
      dispatch({ 
        type: collegeConstants.GET_LIBRARY_SUCCESS,
        payload: data?.data
      });     
    }catch(error){
      dispatch({ 
        type: collegeConstants.GET_LIBRARY_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const createLibraryUser = (form) => {
  return async dispatch => {
    try{
      dispatch({ type: collegeConstants.CREATE_LIBRARY_USER_REQUEST });      
      const { data } = await Axios.post(`/auth/library/register`, form);            
      dispatch({ 
        type: collegeConstants.CREATE_LIBRARY_USER_SUCCESS,
        payload: { message: data?.message } 
      });     
      dispatch(getLibrary(form?.libraryId));
    }catch(error){
      dispatch({ 
        type: collegeConstants.CREATE_LIBRARY_USER_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const updateLibraryUser = (id, form) => {
  return async dispatch => {
    try{
      dispatch({ type: collegeConstants.UPDATE_LIBRARY_USER_REQUEST });      
      const { data } = await Axios.put(`/library/update/user/${id}`, form);            
      dispatch({ 
        type: collegeConstants.UPDATE_LIBRARY_USER_SUCCESS,
        payload: { message: data?.message } 
      });     
      dispatch(getLibrary(form?.libraryId));
    }catch(error){
      dispatch({ 
        type: collegeConstants.UPDATE_LIBRARY_USER_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getCollegeStudents = (collegeId, sessionId, courseClassId, filter) => {
  return async dispatch => {
    try{
      dispatch({ type: collegeConstants.GET_COLLEGE_STUDENTS_REQUEST });      
      let URL = `/college/admin/students?sessionId=${sessionId}&courseClassId=${courseClassId}&collegeId=${collegeId}`;
      if(filter?.status) URL += `&status=${filter.status}`;
      if(filter?.result) URL += `&result=${filter.result}`;
      if(filter?.category) URL += `&category=${filter.category}`;
      if(filter?.gender) URL += `&gender=${filter.gender}`
      const { data } = await Axios.get(URL);
      dispatch({ 
        type: collegeConstants.GET_COLLEGE_STUDENTS_SUCCESS,
        payload: data?.data
      });           
    }catch(error){
      dispatch({ 
        type: collegeConstants.GET_COLLEGE_STUDENTS_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}