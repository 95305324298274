import { apiConfig } from "config";

export const isMobile = () => (typeof window !== "undefined" ? window.innerWidth <= 600 : false);
export const isTab = () => (typeof window !== "undefined" ? window.innerWidth <= 1024 : false);

export const formatDate = (date) => {
  let formattedDate;
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thusday", "Friday", "Saturday"]
  if(typeof date === "object"){
    formattedDate = `${dayNames[date?.toDate().getDay()]}, ${monthNames[date?.toDate().getMonth()]} ${date?.toDate().getDate()}, ${date?.toDate().getFullYear()}`
  }else if(typeof date === "string"){
    formattedDate = `${monthNames[new Date(date).getMonth()]} ${new Date(date).getDate()}, ${new Date(date).getFullYear()}`
  }
  return formattedDate
}

export const covertHtmlInputDate = (date) => {
  let _date = new Date(date);
  return _date.toLocaleDateString("sv-SE");
}

export const scrollToTop = (window) =>{
  window.scrollTo({top: 0, left: 0, behavior: "smooth" })
}    

export const printINR = (cost) => {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(cost);
}
export const getAssetURL = (filename) => {
  return "/assets/"+filename;
}

export const generatePublicUrl = (file) => {
  if(file?.includes("http") || file?.includes("https")) return file;
  if(file?.split("/").length > 1) return file;
  return apiConfig.MEDIA_URL + "/" + file
}

export function flattenFaculties(faculties) {
  let flat = [];
  for (let fac of faculties) {
      flat.push({ label: fac.name, value: fac.id });
      if (fac.faculties) {
        flat = flat.concat(flattenFaculties(fac.faculties));
      }
  }
  return flat;
}

export const formatName = (firstName, middleName, lastName) => {  
  let _middleName = middleName ? ` ${middleName}` : "";
  let _lastName = lastName ? ` ${lastName}` : "";
  return firstName + _middleName + _lastName;
}